import { returnViewport, debounce } from './functions';

class Carousel {
  constructor(mainCarousel) {
    let mainCarouselItemContainer = mainCarousel.find(
      '> .carousel > .carousel-inner',
    );
    let mainCarouselItemContainerItems = mainCarouselItemContainer.find(
      '.carousel-item',
    );

    let lightboxCarousel = mainCarousel.find('.lnk-carousel--lightbox'); // select lightbox carousel

    let isStageCarousel = mainCarousel.hasClass('lnk-carousel--stage');
    if (isStageCarousel) {
      this.stageVideoPaused(mainCarousel);
    }

    let isResponsiveCarousel = mainCarousel.hasClass(
      'lnk-carousel--responsive',
    );

    if (isResponsiveCarousel) {
      this.currentSlidePositionIndex = 0; // number of slides (click events next/prev)
      this.currentSlidePosition = 0; // absolute position after one slide
      this.endlessCarousel = mainCarousel.hasClass('lnk-carousel--endless');
      this.responsiveInit(mainCarousel);
    }

    const carouselItemLength = mainCarouselItemContainerItems.length - 1; // length of items, starting at 0

    /**
     * prevent cycling through element on click
     */
    if (lightboxCarousel.length > 0) {
      $(mainCarousel).on('slide.bs.carousel', function () {
        $(this).carousel('pause');
      });
      $(lightboxCarousel).on('slide.bs.carousel', function () {
        $(this).carousel('pause');
      });
    }

    $(mainCarousel)
      .find('> .carousel > .carousel-control-prev')
      .on('click', function () {
        let nextItem =
          mainCarouselItemContainer.find('.carousel-item.active').index() - 1;
        nextItem < 0 ? (nextItem = carouselItemLength) : '';
        lightboxCarousel.carousel(nextItem);
      });

    $(mainCarousel)
      .find('> .carousel > .carousel-control-next')
      .on('click', function () {
        let nextItem =
          mainCarouselItemContainer.find('.carousel-item.active').index() + 1;
        nextItem > carouselItemLength ? (nextItem = 0) : '';
        lightboxCarousel.carousel(nextItem);
      });

    $(lightboxCarousel)
      .find('> .carousel-control-prev')
      .on('click', function () {
        let nextItem =
          mainCarouselItemContainer.find('.carousel-item.active').index() - 1;
        nextItem < 0 ? (nextItem = carouselItemLength) : '';
        mainCarousel.carousel(nextItem);
      });

    $(lightboxCarousel)
      .find('> .carousel-control-next')
      .on('click', function () {
        let nextItem =
          mainCarouselItemContainer.find('.carousel-item.active').index() + 1;
        nextItem > carouselItemLength ? (nextItem = 0) : '';
        mainCarousel.carousel(nextItem);
      });

    $(mainCarousel)
      .find('> .carousel > .carousel-indicators li')
      .on('click', function () {
        lightboxCarousel.carousel($(this).index());
      });

    $(lightboxCarousel)
      .find('> .carousel-indicators li')
      .on('click', function () {
        mainCarousel.carousel($(this).index());
      });
  }

  responsiveInit(mainCarousel) {
    let that = this;

    that.responsiveHandling(mainCarousel);
    /** event: resize, to determine the viewport + function call: responsiveHandling() */
    $(window).on(
      'resize',
      debounce(function () {
        that.responsiveHandling(mainCarousel);
      }, 300),
    );
  }

  responsiveHandling(mainCarousel) {
    let that = this;
    let viewports = returnViewport().viewports;
    let currentViewport = returnViewport().currentViewport;
    let elementsPerSlide = 1;
    let allCarouselElements = mainCarousel.find('.carousel-item > *');
    let numberOfElements = allCarouselElements.length;
    let elementsWidth = 0;

    const hasPreview = mainCarousel
      .parent()
      .hasClass('lnk-teaser-carousel--has-preview');

    /**
     *  every defined viewport has his own slide number
     *  if the viewport not defined the viewport becomes the next lower viewport
     */
    if (mainCarousel.data(currentViewport)) {
      /**
       * viewport is defined in the carousel
       */
      elementsPerSlide = mainCarousel.data(currentViewport);
    } else {
      /**
       *  the viewport becomes the value of the next lower viewport
       */
      for (let i = viewports.indexOf(currentViewport); i > -1; i--) {
        if (mainCarousel.data(viewports[i])) {
          elementsPerSlide = mainCarousel.data(viewports[i]);
          break;
        }
      }
    }

    /**
     * elementsPerSlide === smalles viewport (smartphone)
     * here we need a extra class with styling attributes for the teaser + teaser text
     */
    if (elementsPerSlide === 1) {
      mainCarousel
        .find('.carousel-item > .lnk-teaser ')
        .addClass('lnk-teaser--one-carousel-slide-item');
    } else {
      mainCarousel
        .find('.carousel-item > .lnk-teaser')
        .removeClass('lnk-teaser--one-carousel-slide-item');
    }

    /**
     * marginRightOffsetPerElement is for the correct positioning in the responsive teaser carousel (margin)
     * elementsWidth calculates the width in the current viewport
     */
    let marginRightOffsetPerElement =
      parseInt(
        mainCarousel.find('.carousel-item > *').first().css('padding-right'),
      ) / (elementsPerSlide === 1 ? 1 : elementsPerSlide - 1);

    const itemWidth =
      hasPreview && elementsPerSlide === 1 ? this.getPreviewWidth() : 100;
    elementsWidth = (1 / elementsPerSlide) * itemWidth;
    mainCarousel.find('.carousel-item > *').css({
      width: elementsWidth + '%',
      marginRight: marginRightOffsetPerElement + 'px',
    });

    /**
     * If you change the viewport, the carousel position will remain the same
     * currentSlidePosition will be recalculated when the viewport is made larger
     * currentSlidePositionIndex will be recalculated when the viewport is made larger
     */
    this.currentSlidePosition =
      elementsWidth * this.currentSlidePositionIndex * -1;
    if (
      this.currentSlidePosition <
      (allCarouselElements.length - elementsPerSlide) * elementsWidth * -1
    ) {
      this.currentSlidePosition =
        (allCarouselElements.length - elementsPerSlide) * elementsWidth * -1;
      this.currentSlidePositionIndex =
        allCarouselElements.length - elementsPerSlide;
    }

    /**
     * Initialization of the elements in the carousel when changing the viewport
     */
    mainCarousel
      .find('.carousel-item')
      .css(
        'transform',
        'translateX(' + that.currentSlidePosition + '%) translateX(-' + marginRightOffsetPerElement * that.currentSlidePositionIndex + 'px)',
      ); // prettier-ignore

    /**
     * handling visibility of arrows
     */
    if (numberOfElements <= elementsPerSlide) {
      mainCarousel.find('.carousel-control-next').addClass('invisible');
      mainCarousel.find('.carousel-control-prev').addClass('invisible');
    } else {
      mainCarousel.find('.carousel-control-next').removeClass('invisible');
      mainCarousel.find('.carousel-control-prev').removeClass('invisible');
    }
    /**
     * initial control prev arrow hidden
     */
    if (!that.endlessCarousel && this.currentSlidePositionIndex === 0) {
      mainCarousel.find('.carousel-control-prev').addClass('invisible');
    }
    /**
     * small viewport to bigger viewport arrows
     */
    if (
      !that.endlessCarousel &&
      that.currentSlidePosition <=
        (allCarouselElements.length - elementsPerSlide) * elementsWidth * -1
    ) {
      mainCarousel.find('.carousel-control-next').addClass('invisible');
    }
    /**
     * click event on arrow next
     */
    mainCarousel.find('.carousel-control-next').off('click');
    mainCarousel.find('.carousel-control-next').on('click', function () {
      mainCarousel.find('.carousel-control-prev').removeClass('invisible');
      let control = $(this);
      that.currentSlidePosition = that.currentSlidePosition - elementsWidth;
      that.currentSlidePositionIndex++;
      /**
       * !that.endlessCarousel
       * at last element arrow will be hide
       */
      if (
        !that.endlessCarousel &&
        that.currentSlidePosition <=
          (allCarouselElements.length - elementsPerSlide) * elementsWidth * -1
      ) {
        control.addClass('invisible');
      }

      /**
       * transition styles and properties
       * - gray arrow
       * - cant click on teaser etc.
       */
      control.addClass('lnk-carousel-control--disabled');
      mainCarousel.find('.carousel-item').removeClass('transition-none');
      mainCarousel.find('.carousel-item').css('pointer-events', 'none');

      /**
       * after transition
       */
      mainCarousel.find('.carousel-item').on('transitionend', function () {
        $(this).off('transitionend');
        /**
         * endless carousel
         * data will set to inital value
         */
        if (that.endlessCarousel) {
          let element = $(this).children().first();
          element.remove();
          $(this).append(element.clone());
          that.currentSlidePosition = 0;
          that.currentSlidePositionIndex = 0;
          mainCarousel.find('.carousel-item').addClass('transition-none');
          mainCarousel
            .find('.carousel-item')
            .css(
              'transform',
              'translateX(' +  that.currentSlidePosition + '%) translateX(-' + marginRightOffsetPerElement * that.currentSlidePositionIndex + 'px)',
            ); // prettier-ignore
        }
        /**
         * transition finish
         * - gray removed
         * - click on teaser now possible
         */
        control.removeClass('lnk-carousel-control--disabled');
        $(this).css('pointer-events', 'auto');
      });
      /** the animation of the slide */
      mainCarousel
        .find('.carousel-item')
        .css(
          'transform',
          'translateX(' + that.currentSlidePosition + '%) translateX(-' + marginRightOffsetPerElement * that.currentSlidePositionIndex + 'px)',
        ); // prettier-ignore
    });

    /**
     * click event on arrow prev
     */
    mainCarousel.find('.carousel-control-prev').off('click');
    mainCarousel.find('.carousel-control-prev').on('click', function () {
      let control = $(this);
      if (!that.endlessCarousel) {
        mainCarousel.find('.carousel-control-next').removeClass('invisible');
      }
      /**
       * endless carousel need a different start values on
       * - that.currentSlidePosition
       * - that.currentSlidePositionIndex
       */
      if (that.endlessCarousel) {
        that.currentSlidePosition = elementsWidth * -1;
        that.currentSlidePositionIndex = 1;
      } else {
        that.currentSlidePosition = that.currentSlidePosition + elementsWidth;
        that.currentSlidePositionIndex--;
      }

      /**
       * control arrow
       * !endlessCarousel and on index 1 = hide
       */
      if (!that.endlessCarousel && that.currentSlidePositionIndex <= 0) {
        control.addClass('invisible');
      } else {
        control.removeClass('invisible');
      }

      /**
       * endless carousel
       * remove the last element
       * element will place on position 1
       *
       * css('left') workaround for the final tranisition (only 1 transition in click event possible)
       */
      if (that.endlessCarousel) {
        let element = mainCarousel.find('.carousel-item').children().last();
        element.remove();
        mainCarousel.find('.carousel-item').prepend(element.clone());
        mainCarousel
          .find('.carousel-item')
          .css(
            'left',
            'calc(' + that.currentSlidePosition + '% - ' + marginRightOffsetPerElement * that.currentSlidePositionIndex + 'px)',
          ); // prettier-ignore
      }

      /**
       * transition styles and properties
       * - gray arrow
       * - cant click on teaser etc.
       */
      control.addClass('lnk-carousel-control--disabled');
      mainCarousel.find('.carousel-item').removeClass('transition-none');
      mainCarousel.find('.carousel-item').css('pointer-events', 'none');

      /**
       * after transition
       */
      mainCarousel.find('.carousel-item').on('transitionend', function () {
        $(this).off('transitionend');

        /**
         * endless carousel
         * data will set to inital value
         */
        if (that.endlessCarousel) {
          that.currentSlidePosition = 0;
          that.currentSlidePositionIndex = 0;
          /** the transition is disabled for transform(284) */
          mainCarousel.find('.carousel-item').addClass('transition-none');
          mainCarousel
            .find('.carousel-item')
            .css(
              'transform',
              'translateX(' + that.currentSlidePosition + '%) translateX(-' + marginRightOffsetPerElement * that.currentSlidePositionIndex + 'px)',
            ); // prettier-ignore
          mainCarousel.find('.carousel-item').css('left', 'auto');
        }

        /**
         * transition finish
         * - gray removed
         * - click on teaser now possible
         */
        control.removeClass('lnk-carousel-control--disabled');
        $(this).css('pointer-events', 'auto');
      });

      /**
       * the animation of the slide
       * endlessCarousel
       */
      if (that.endlessCarousel) {
        mainCarousel.find('.carousel-item').css(
          'transform',
          'translateX(' + that.currentslideposition * -1 + '%) translateX(' + marginRightOffsetPerElement * that.currentSlidePositionIndex + 'px)',
        ); // prettier-ignore
        /** !endless carousel */
      } else {
        mainCarousel
          .find('.carousel-item')
          .css(
            'transform',
            'translateX(' + that.currentSlidePosition + '%) translateX(-' + marginRightOffsetPerElement * that.currentSlidePositionIndex + 'px)',
          ); // prettier-ignore
      }
    });
  }

  stageVideoPaused(mainCarousel) {
    $(mainCarousel).on('slide.bs.carousel', function () {
      $(mainCarousel)
        .find('video[data-is-vjs]')
        .each(function (index, element) {
          element.pause();
        });
    });
  }

  getPreviewWidth() {
    if (
      returnViewport().currentViewport === 'xs' ||
      returnViewport().currentViewport === 'sm'
    ) {
      return 75;
    }

    return 60;
  }
}

export default Carousel;
